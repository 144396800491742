import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  loadTinyFaceDetectorModel,
  detectSingleFace,
  detectAllFaces,
  TinyFaceDetectorOptions
} from "face-api.js";
import { useTranslation } from "react-i18next";
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import Webcam from "react-webcam";
import { sendProfilePictureToWF } from "../../Providers/backend";

function FaceCapture(props: {
  patient?: string;
  step: number;
  onStepChange: (pageNum: React.SetStateAction<number>) => void;
  isRendered: (pageNum: React.SetStateAction<boolean>) => void;
}) {
  const { t } = useTranslation();
  const [video, setVideo]: any = useState(null);
  const [canvas, setCanvas]: any = useState(null);
  const [detected, setDetected]: any = useState(false);
  const [camera, setCamera]: any = useState(false);
  const [imageSrc, setImageSrc]: any = useState("");
  const [check1, setCheck1] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const videoRef: any = useRef(null);
  const canvasRef: any = useRef(null);
  const webcamRef: any = useRef(null);
  const [image, setImage] = useState(null);

  // Wait function
  const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  // Capture a picture
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
  }, [webcamRef]);

  useEffect(() => {
    setVideo(videoRef.current);
    setCanvas(canvasRef.current);
    setCheck1(!check1);
  }, []);

  // Stop camera manually or after capturing a picture
  const stopCam = () => {
    if (video.srcObject) {
      video.srcObject.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    }
    setCamera(false);
  };

  // Start face detection
  const start = async () => {
    await launchCamera();
    const recognition = makeRecognition();
    await recognition.init();
    recognition.start();
  };

  // Face-api.js definition
  const getFaceDetectorOptions = () =>
    new TinyFaceDetectorOptions({ inputSize: 224, scoreThreshold: 0.5 });
  const makeRecognition = () => {
    let ctx: any;
    const init = async () => {
      await loadTinyFaceDetectorModel("models");
      ctx = canvas.getContext("2d");
    };

    // Start face detection function
    const start = async () => {
      await wait(0);
      if (video.readyState === 4) {
        const faces = await detectSingleFace(video, getFaceDetectorOptions());
        const fullFaceDescriptions = await detectAllFaces(video, getFaceDetectorOptions());
        if (faces && fullFaceDescriptions.length === 1) {
          setDetected(true);
        } else {
          setDetected(false);
          ctx.clearRect(0, 0, video.videoWidth, video.videoHeight);
        }
      }
      start();
    };

    return { init, start };
  };

  const videoConstraints = {
    width: 500,
    height: 320,
    facingMode: "user"
  };

  // Launch UI camera with permission check
  const launchCamera = () =>
    new Promise((resolve: any, reject: any) => {
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: videoConstraints // Updated constraints
      })
        .then((stream: any) => {
          video.srcObject = stream;
          video.play();
          setCamera(true);
          setErrorMessage(""); // Clear any previous error messages
          resolve();
        })
        .catch((error: any) => {
          setErrorMessage(t("errorCamera")); // Set error message
          reject(error);
        });
    });

  return (
    <>
      <Typography variant="h5" component="div" align="center" style={{ fontWeight: "bold", marginBottom: "3vh" }}>
        {t("pressLaunch")}
      </Typography>
      <div style={{ margin: "auto" }}>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>} {/* Display error message */}
        <Grid>
          <FormControlLabel control={
            <Grid container>
              <Grid item xs={1}>
                <Checkbox checked={!check1} onChange={() => {
                  setCheck1(!check1);
                  if (check1 === false) {
                    setImageSrc("");
                    setCamera(false);
                    stopCam();
                  } else {
                    start();
                    window.scrollTo(0, document.body.scrollHeight);
                  }
                }} />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1" gutterBottom component="div" align="justify">
                  {t("confirmPic")}
                </Typography>
              </Grid>
            </Grid>
          } label="" style={{ minWidth: 125 }} />
        </Grid>
        {!camera ? (
          <Box mt={1}>
            {imageSrc && !camera ? (
              <img className="" src={imageSrc} alt="yourimage" />
            ) : null}
            {imageSrc ?
              <Box>
                <Button
                  style={imageSrc ? {
                    padding: 20,
                    fontSize: 14,
                    background: "#6d6d86",
                    color: "#fff",
                    margin: "auto",
                    borderRadius: 20
                  } : {
                    padding: 20,
                    fontSize: 14,
                    backgroundColor: "#8d2d46",
                    color: "#fff",
                    margin: "auto",
                    borderRadius: 20
                  }}
                  onClick={() => {
                    start();
                    window.scrollTo(0, document.body.scrollHeight);
                  }}
                >
                  {t("retake")}
                </Button>
                <Button style={{
                  padding: 20,
                  fontSize: 14,
                  backgroundColor: "#8d2d46",
                  color: "#fff",
                  margin: "auto",
                  borderRadius: 20
                }}
                  onClick={() => {
                    sendProfilePictureToWF(imageSrc.slice(23), `p${props.patient}.jpg`, props.patient + "");
                    if (video.srcObject) {
                      const tracks = video.srcObject.getTracks();
                      tracks.forEach((track: MediaStreamTrack) => track.stop());
                    }
                    props.isRendered(false);
                  }}
                >
                  {t("sendPic")}
                </Button>
              </Box> : null}
          </Box>
        ) : (
          <Box>
            <Box>
              <Webcam
                audio={false}
                ref={webcamRef}
                mirrored={true}
                screenshotFormat="image/jpeg"
                screenshotQuality={0.92}
                minScreenshotHeight={250}
                imageSmoothing={true}
                minScreenshotWidth={155}
                videoConstraints={videoConstraints} // Use the defined constraints
              />
            </Box>
            <Typography variant="subtitle1" component="div" align="center" style={{ fontWeight: "bold", marginTop: "2vh" }}>
              {t("cameraInstruction")}
            </Typography>
          </Box>
        )}
        <video
          style={{ position: "absolute", top: 70, left: 10, visibility: "hidden" }}
          ref={videoRef}
        />
        <canvas
          style={{ position: "absolute", top: 70, left: 10, visibility: "hidden" }}
          ref={canvasRef}
        />
        <Box mt={2}>
          {camera && (
            <Button
              style={{
                padding: 20,
                fontSize: 14,
                background: "#6d6d86",
                color: "#fff",
                margin: "auto",
                borderRadius: 70
              }}
              onClick={() => {
                setImageSrc("");
                stopCam();
                setCheck1(!check1);
              }}
            >
              {t("stopCam")}
            </Button>
          )}
          {camera && (
            detected ? (
              <Button
                style={{
                  padding: 20,
                  fontSize: 14,
                  backgroundColor: "#8d2d46",
                  color: "#fff",
                  margin: "auto",
                  borderRadius: 70,
                }}
                onClick={() => {
                  setImageSrc(image);
                  capture();
                  stopCam();
                  setCamera(false);
                  setDetected(false);
                }}
              >
                {t("takePic")}
              </Button>
            ) : (
              <Button
                style={{
                  padding: 20,
                  fontSize: 14,
                  background: "#b68d98",
                  color: "#fff",
                  margin: "auto",
                  borderRadius: 70,
                }}
                disabled
              >
                {t("takePic")}
              </Button>
            )
          )}
        </Box>
      </div>
    </>
  );
}

export default FaceCapture;
