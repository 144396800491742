import React, { ReactElement, useState, useRef, useEffect } from "react";
import { Box, Grid, List, ListItem, Typography, Button, Checkbox } from "@material-ui/core";
import "react-phone-input-2/lib/style.css";
import {
    Dialog, DialogActions, DialogTitle, DialogContent,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
import {
    Page, Text, View, Document, Image,
    PDFDownloadLink, StyleSheet, Font, pdf, PDFViewer
} from "@react-pdf/renderer";
import logo from "./Assets/logo.png";
import Cookies from "js-cookie";
import { PopupAlert } from "../../../../Hooks";
import { sendDeclarationToNC } from "../../../../Providers/backend";

function ImageAgreement({ patient, step, onStepChange, isRendered }: {
    patient?: string;
    step: number;
    onStepChange: (pageNum: React.SetStateAction<number>) => void;
    isRendered: (pageNum: React.SetStateAction<boolean>) => void;
}): ReactElement {
    const [count, setCount] = useState(0);
    const [isSigned, setIsSigned] = useState<boolean>(false);
    const [signature, setSignature] = useState<string | undefined>();
    const [open, setOpen] = useState(false);
    const sigCanvas = useRef<SignatureCanvas | null>(null);
    const { t } = useTranslation();
    let blobURL;

    const patientID = patient ?? "";
    const today = new Date();
    const todayDate = today.toLocaleDateString().replaceAll("г.", "").replaceAll(".", "/");
    const namingPDF = `declaration_${patientID}_${Date.now()}.pdf`;

    Font.register({
        family: "Roboto",
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf"
    });

    const styles = StyleSheet.create({
        page: { fontFamily: "Roboto", fontWeight: 500 },
        inline: { height: 90, width: 200, margin: "0 auto" }
    });

    useEffect(() => {
        PDFFile();
    }, []);

    const handleClose = () => setOpen(false);

    const handleSignatureEnd = () => {
        if (sigCanvas.current) {
            setSignature(sigCanvas.current.toDataURL());
        }
    };

    const checkLocation = Cookies.get("facilityName") === "Dentaprime%20London" ||
        Cookies.get("facilityName") === "Dentaprime London";

    const signatureValidator = () => {
        if (sigCanvas.current?.isEmpty()) {
            PopupAlert("The signature must not be empty", "error");
        } else {
            PDFFile();
            setOpen(true);
        }
    };

    const uploadFile = async () => {
        const pdfBlob = await pdf(<PDFFile />).toBlob();
        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = () => {
            const base64data = String(reader.result);
            sendDeclarationToNC(base64data, "declaration", patientID);
        };
    };

    const PDFFile = () => (
        <Document title={namingPDF}>
            <Page size="A4" style={styles.page}>
                <View style={styles.inline}><Image src={logo} /></View>
                <View style={{ textAlign: "center" }}><Text>{t("Image Agreement")}</Text></View>
                <View style={{ textAlign: "justify", margin: 10 }}>
                    <Text>{checkLocation ? t("DeclarationText1F3T") : t("DeclarationTextP1")}</Text>
                </View>
                <View style={{ textAlign: "justify", margin: 10 }}><Text>{t("DeclarationTextP2")}</Text></View>
                {!sigCanvas.current?.isEmpty() && (
                    <View style={{ margin: 15 }}>
                        <Text>{t("PatientNameSignature")}</Text>
                        <Image src={signature} />
                    </View>
                )}
                <View style={{ margin: 15 }}>
                    <Text>{checkLocation ? t("London") : t("Varna")}</Text>
                    <Text>{todayDate}</Text>
                </View>
                <View>
                    <Text style={{ textAlign: "right", marginBottom: 0 }}>{t("DocumentVersion")}</Text>
                </View>
            </Page>
        </Document>
    );

    const handleSignatureClear = () => sigCanvas.current?.clear();

    const handleContinue = () => {
        setSignature(sigCanvas.current?.toDataURL("image/png"));
        signatureValidator();
    };

    return (
        <List>
            <Box sx={{ margin: "auto", padding: "0 0.5em" }}>
                <Typography variant="h6" gutterBottom component="div" align="justify">
                    {checkLocation ? t("DeclarationText1F3T") : t("DeclarationTextP1")}
                </Typography>
                <Typography variant="h6" gutterBottom component="div" align="justify">
                    {t("DeclarationTextP2")}
                </Typography>
                <ListItem alignItems="center">
                    <Box sx={{ border: "3px solid grey", margin: "auto", borderRadius: 10 }}>
                        <Typography variant="h6" gutterBottom component="div">
                            {t("PatientNameSignature")}
                        </Typography>
                        <SignatureCanvas
                            ref={sigCanvas}
                            penColor="red"
                            canvasProps={{ width: window.innerWidth * 0.8, height: window.innerHeight * 0.3, className: "sigCanvas" }}
                            backgroundColor="#f4f5f8"
                            onEnd={handleSignatureEnd}
                        />
                        <Grid>
                            <Typography variant="h6" gutterBottom component="h6">{t("Varna")},</Typography>
                            <Typography variant="h6" gutterBottom component="h6">{todayDate}</Typography>
                        </Grid>
                    </Box>
                </ListItem>
                <Box sx={{ textAlign: "right", marginTop: 10 }}>
                    <Typography variant="h6" gutterBottom component="h6">{t("DocumentVersion")}</Typography>
                </Box>
            </Box>
            <Box sx={{ position: "sticky", top: "15px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Button
                            style={{ width: "100%", height: "6.25vh", borderRadius: 0, color: "#fff", background: "linear-gradient(0deg, rgb(100,105,107) 0%, rgb(164,171,177) 100%)" }}
                            onClick={handleSignatureClear}
                        >
                            {t("Clear")}
                        </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <Button
                            style={{ width: "100%", height: "6.25vh", borderRadius: 0, color: "#fff" }}
                            onClick={handleContinue}
                        >
                            {t("Continue")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Are you sure you want to submit?")}</DialogTitle>
                <DialogContent></DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("Cancel")}</Button>
                    <PDFDownloadLink document={<PDFFile />} fileName={namingPDF}>
                        {({ blob }) => {
                            blobURL = blob;
                        }}
                    </PDFDownloadLink>
                    <Button
                        onClick={() => {
                            setCount(count + 1);
                            setTimeout(() => {
                                uploadFile();
                                setOpen(false);
                                isRendered(false);
                                window.scrollTo(0, 0);
                            }, 1000);
                        }}
                    >
                        {t("Submit")}
                    </Button>
                </DialogActions>
            </Dialog>
            {localStorage.getItem("local") === "true" && (
                <Grid container>
                    <Grid item xs={1}>
                        <Checkbox
                            checked={isSigned}
                            onChange={() => {
                                setIsSigned(true);
                                onStepChange(step + 1);
                                window.scrollTo(0, 0);
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="body1" gutterBottom component="div" align="justify">
                            {t("hideDeclaration")}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </List>
    );
}

export default ImageAgreement;
