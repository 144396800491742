import { Button, Fade, FormControl, FormLabel, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import React, { ReactElement } from "react";
import FormattedText from "../../Components/FormattedText";
import { TRANSITION_TIMEOUT } from "../../Resources/constants";
import { useOnSubmit } from "./Hooks";

const AdminPanel = (): ReactElement => {

    const onSubmit = useOnSubmit();

    const formik = useFormik({
        initialValues: { id: "" }, // Keep id as string
        onSubmit: a => onSubmit(a), // Submit as string
    });

    return (
        <form
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "60vh",
            }}
            onSubmit={formik.handleSubmit}
        >
            <Fade in={true} timeout={TRANSITION_TIMEOUT}>
                <FormControl>
                    <FormLabel style={{ marginBottom: "32%" }}>
                        <FormattedText text='Enter user ID' variant='h1' />
                    </FormLabel>
                    <FormControl
                        style={{
                            textAlign: "left",
                            padding: "3%",
                        }}>
                        <FormLabel>
                            <FormattedText text='ID' variant='subtitle1' />
                        </FormLabel>
                        <TextField
                            style={{
                                marginBottom: "10%"
                            }}
                            fullWidth
                            id='id'
                            name='id'
                            type='number' // Field type is number
                            placeholder='Enter your ID'
                            onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue("id", value.toString()); // Convert the value to string
                            }}
                            value={formik.values.id} // Keep the value as string
                            InputProps={{ inputProps: { min: 0 } }} // Optional: Prevent negative numbers
                        />
                        <Button type='submit'>
                            <FormattedText text='Submit' />
                        </Button>
                    </FormControl>
                </FormControl>
            </Fade>
        </form>
    );
};

export default AdminPanel;
